import React from 'react';
import { Club } from '../types';
import {calculateDistance} from "../utils/geo";

interface ClubListProps {
    clubs: Club[];
    userCoordinates?: { lat: number; lon: number };
}

const ClubList: React.FC<ClubListProps> = ({ clubs, userCoordinates }) => {
    return (
        <ul className="club-list">
            {clubs.map((club) => (
                <li key={club.id} className="club">
                    <h3>{club.name}</h3>
                    <p>Dates: {club.dates[0].toDateString()} - {club.dates[club.dates.length - 1].toDateString()}</p>
                    <p>Age Range: {club.ageRange}</p>
                    <p>Location: {club.location}</p>
                    <p>Postcode: {club.postcode}</p>
                    {userCoordinates && (
                        <p>
                            Distance: {calculateDistance(
                            userCoordinates.lat,
                            userCoordinates.lon,
                            club.coordinates.lat,
                            club.coordinates.lon
                        ).toFixed(2)} km
                        </p>
                    )}
                </li>
            ))}
        </ul>
    );
};

export default ClubList;