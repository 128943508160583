import React from 'react';

interface FiltersProps {
    onFilterChange: (filters: FilterState) => void;
}

export interface FilterState {
    date: string;
    age: string;
    postcode: string;
    maxDistance: string;
}

const Filters: React.FC<FiltersProps> = ({ onFilterChange }) => {
    const [filters, setFilters] = React.useState<FilterState>({
        date: '',
        age: '',
        postcode: '',
        maxDistance: '10',
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFilters((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        onFilterChange(filters);
    };

    return (
        <form onSubmit={handleSubmit} className="filters">
            <label htmlFor="date">Date:</label>
            <input
                type="date"
                id="date"
                name="date"
                value={filters.date}
                onChange={handleChange}
            />

            <label htmlFor="age">Age:</label>
            <input
                type="number"
                id="age"
                name="age"
                min="0"
                max="18"
                value={filters.age}
                onChange={handleChange}
            />

            <label htmlFor="postcode">Your Postcode:</label>
            <input
                type="text"
                id="postcode"
                name="postcode"
                value={filters.postcode}
                onChange={handleChange}
            />

            <label htmlFor="maxDistance">Max Distance (km):</label>
            <input
                type="number"
                id="maxDistance"
                name="maxDistance"
                min="0"
                value={filters.maxDistance}
                onChange={handleChange}
            />

            <button type="submit">Filter Clubs</button>
        </form>
    );
};

export default Filters;