import React, { useState } from 'react';
import ClubList from './components/ClubList';
import Filters, { FilterState } from './components/Filters';
import { Club } from './types';
import './App.css';
import {calculateDistance} from "./utils/geo";

const expandDate = (startDate: string, endDate: string): Date[] => {
    const dates = [];
    const startDateObj = new Date(startDate);
    const endDateObj = new Date(endDate);
    for (let d = startDateObj; d <= endDateObj; d.setDate(d.getDate() + 1)) {
        dates.push(new Date(d));
    }
    return dates;
}

const CLUBS: Club[] = [
  {
    id: 1,
    name: "Fun Club",
    dates: expandDate('2024-07-01','2024-07-14'),
    ageRange: "5-12",
    location: "City Center",
    postcode: "EH1 1YZ",
    coordinates: {lat: 55.9533, lon: -3.1883}
  },
  {
    id: 2,
    name: "Science Camp",
    dates: expandDate("2024-07-15", "2024-07-28"),
    ageRange: "8-14",
    location: "University",
    postcode: "EH8 9YL",
    coordinates: {lat: 55.9445, lon: -3.1892}
  },
  {
    id: 3,
    name: "Sports Academy",
    dates: expandDate("2024-08-01", "2024-08-14"),
    ageRange: "6-16",
    location: "Sports Complex",
    postcode: "EH11 2AS",
    coordinates: {lat: 55.9389, lon: -3.2198}
  }
];

const App: React.FC = () => {
  const [clubs] = useState<Club[]>(CLUBS);
  const [filteredClubs, setFilteredClubs] = useState<Club[]>(CLUBS);
  const [userCoordinates, setUserCoordinates] = useState<{lat: number, lon: number} | undefined>();

  const getCoordinates = async (postcode: string): Promise<{lat: number, lon: number} | undefined> => {
    try {
      const response = await fetch(`https://api.postcodes.io/postcodes/${postcode}`);
      const data = await response.json();
      if (data.status === 200) {
        return { lat: data.result.latitude, lon: data.result.longitude };
      } else {
        return undefined;
      }
    } catch (error) {
      console.error('Error fetching coordinates:', error);
      return undefined;
    }
  };

  const handleFilterChange = async (filters: FilterState) => {
    if (filters.postcode) {
      const userCoords = await getCoordinates(filters.postcode);
      setUserCoordinates(userCoords);
    }

    const filtered = clubs.filter((club) => {
      const [startDate, endDate] = [club.dates[0], club.dates[club.dates.length - 1]];
      const filterDate = new Date(filters.date);
      const [minAge, maxAge] = club.ageRange.split('-').map(Number);
      const filterAge = Number(filters.age);
      const filterMaxDistance = Number(filters.maxDistance);
      const distance = userCoordinates ? calculateDistance(userCoordinates.lat, userCoordinates.lon, club.coordinates.lat, club.coordinates.lon) : 0;

      return (
          (!filters.date || (filterDate >= startDate && filterDate <= endDate)) &&
          (!filters.age || (filterAge >= minAge && filterAge <= maxAge)) &&
          (!filters.maxDistance || (filterMaxDistance >= distance))
      );
    });

    setFilteredClubs(filtered);
  };

  return (
      <div className="App">
        <h1>Edinburgh Holiday Clubs</h1>
        <Filters onFilterChange={handleFilterChange} />
        <ClubList clubs={filteredClubs} userCoordinates={userCoordinates} />
      </div>
  );
};

export default App;